<template>
  <div style="background-color: #f6f6f6">
    <van-nav-bar
      title="商品详情"
      :left-arrow="!share"
      @click-left="$goBack()"
      @touchmove.prevent
      fixed
      placeholder
      @click-right="shareBoxShow = true"
    >
      <template #right>
        <img
          v-if="!(empty || share)"
          src="../../assets/img/takeOutFood/share.png"
          alt=""
          style="width: 24px; height: 24px"
        />
      </template>
    </van-nav-bar>

    <van-empty image="error" description="商品不存在" v-if="empty" />

    <div v-else>
      <!-- 轮播图 -->
      <van-swipe @change="swipeChange">
        <van-swipe-item v-for="(item, index) in detail.images" :key="index">
          <van-image :src="item" />
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator">
            {{ swipeCurrent + 1 }}/{{ detail.images.length }}
          </div>
        </template>
      </van-swipe>

      <!--商品标题-->
      <van-cell-group>
        <van-cell>
          <template slot="title">
            <span style="font-size: 17px; font-weight: 500">
              {{ detail.subject }}
            </span>
          </template>
          <template slot="label">
            <div
              style="
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
              "
            >
              <div style="color: #a80000">
                <span style="font-size: 14px">￥</span>
                <span style="font-size: 28px">{{ priceDown }}</span>
                <span v-if="priceUp > priceDown">
                  <span style="font-size: 14px"> ~ ￥</span>
                  <span style="font-size: 28px">{{ priceUp }}</span>
                </span>
              </div>
              <div>
                <span>已售</span>
                <span>{{ detail.saleNum ? detail.saleNum : "0" }}</span>
              </div>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group style="margin: 10px 0">
        <!-- 选择规格 -->
        <van-cell v-if="skuList.length" center is-link @click="popupShow('')">
          <template slot="title">
            <div style="display: flex; align-items: center; height: 36px">
              <div
                style="
                  font-size: 17px;
                  padding-right: 15px;
                  border-right: 1px solid #ddd;
                  margin-right: 15px;
                  width: 70px;
                "
              >
                选择规格
              </div>
              <div style="font-size: 17px; font-weight: 400; color: #999">
                请选择商品规格
              </div>
            </div>
          </template>
        </van-cell>

        <!-- 选择地址 -->
        <!-- <van-cell center is-link @click="$router.push('/shippingAddress')">
          <template slot="title">
            <div style="display: flex; align-items: center; height: 36px">
              <div
                style="
                  font-size: 17px;
                  padding-right: 15px;
                  border-right: 1px solid #ddd;
                  margin-right: 15px;
                  width: 70px;
                "
              >
                配送地址
              </div>
              <div style="font-size: 17px; font-weight: 400; color: #999">
                请选择配送地址
              </div>
            </div>
          </template>
        </van-cell> -->
      </van-cell-group>

      <!-- 店铺信息 -->
      <van-cell-group style="margin: 10px 0">
        <van-cell center is-link @click="toStore">
          <template slot="title">
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 36px;
              "
            >
              <div style="display: flex; align-items: center">
                <van-image width="30" height="30" :src="detail.logoUrl" />
                <div
                  style="font-weight: 500; font-size: 12px; margin-left: 10px"
                >
                  <span style="font-size: 14px">{{ detail.storeName }}</span>
                  &nbsp;
                  <span style="color: #ff5200">{{
                    detail.followNum || "--"
                  }}</span>
                  <span style="color: #999">关注</span>
                </div>
              </div>
              <div>进入店铺</div>
            </div>
          </template>
        </van-cell>
      </van-cell-group>

      <!-- 商品描述 -->
      <div v-html="detail.description" style="overflow: hidden"></div>

      <!-- 底部按钮 -->
      <!-- <van-goods-action
        style="
          padding: 0 10px;
          padding-bottom: constant(safe-area-inset-bottom);
          padding-bottom: env(safe-area-inset-bottom);
        "
      >
        <van-goods-action-icon
          icon="chat-o"
          text="联系商家"
          @click="connect()"
        />
        <van-goods-action-icon icon="cart-o" text="购物车" @click="jump(6)" />
        <van-goods-action-button
          type="danger"
          color="#313131"
          text="加入购物车"
          @click="popupShow('cart')"
        />
        <van-goods-action-button
          type="danger"
          color="linear-gradient(to right, #FF7200, #FF3C00)"
          text="立即购买"
          @click="popupShow('buy')"
        />
      </van-goods-action> -->
      <div style="height: 60px"></div>
    </div>

    <!-- 弹框 -->
    <van-popup
      v-model="show"
      position="bottom"
      closeable
      close-icon="clear"
      round
      safe-area-inset-bottom
    >
      <div class="popup-inner">
        <div class="info">
          <van-image width="100" height="100" :src="detail.images[0]" />
          <div class="info-right">
            <div style="color: #a80000">
              <span style="font-size: 14px">￥</span>
              <span style="font-size: 28px">
                {{ sku ? sku.salePrice : priceDown }}
              </span>
            </div>

            <div style="font-size: 12px">
              <div style="color: #999; margin-bottom: 4px">
                <span>库存</span>

                <span>{{
                  sku
                    ? sku.amountOnSale
                    : detail.amountOnSale
                    ? detail.amountOnSale
                    : "--"
                }}</span>
              </div>

              <div style="color: #333" v-if="detail.makerProductSkus">
                请选择规格
              </div>
            </div>
          </div>
        </div>

        <!-- 规格 -->
        <div style="max-height: 300px; overflow: scroll">
          <div class="size" v-for="(item, index) in skus" :key="index">
            <div style="margin-bottom: 10px">{{ item.attributeName }}</div>
            <div>
              <van-button
                v-for="(item2, index2) in item.values"
                :key="index2"
                size="small"
                class="size-btn"
                :color="item2.chosen ? '#FF5200' : '#eee'"
                :plain="item2.chosen"
                :class="item2.chosen ? 'chosen' : ''"
                @click="chooseSku(item2, index)"
                >{{ item2.value }}</van-button
              >
            </div>
          </div>
        </div>

        <div class="num">
          <div>
            <span>购买数量</span>
            <span
              v-if="detail.minOrderQuantity && detail.minOrderQuantity > 1"
              style="color: #999; font-size: 12px"
              >({{ detail.minOrderQuantity }}件起)</span
            >
          </div>
          <van-stepper
            v-model="quantity"
            integer
            :min="detail.minOrderQuantity ? detail.minOrderQuantity : 1"
          />
        </div>

        <van-button
          v-if="popupBtnType"
          block
          round
          color="linear-gradient(to left, #FF3C00, #FF7200)"
          @click="popupConfirm"
          >确定</van-button
        >

        <!-- <div v-else>
          <van-button
            color="#313131"
            style="width: 50%; border-radius: 999px 0 0 999px"
            @click="addCart"
            >加入购物车</van-button
          ><van-button
            color="linear-gradient(to left, #FF3C00, #FF7200)"
            style="width: 50%; border-radius: 0 999px 999px 0"
            @click="buy"
            >立即购买</van-button
          >
        </div> -->
      </div>
    </van-popup>

    <share-box v-model="shareBoxShow" :data="shareData"></share-box>
  </div>
</template>

<script>
import { getProduct, addCart } from "@/api/makerStore";
import shareBox from "@/components/share-box";
// import { openApp } from "@/common";
export default {
  name: "makerGoodsDetail",
  components: { shareBox },
  data() {
    return {
      swipeCurrent: 0,

      id: null,
      share: null,

      detail: {
        images: [""],
        subject: null,
        storeName: null,
        logoUrl: null,
        followNum: null,
        description: null,
        salePrice: null,
      },
      priceDown: null,
      priceUp: null,

      skuList: [], // 商品的所有规格
      skus: [], // 弹框显示的规格
      chosenSku: [], // 弹框选中的规格
      sku: null, //弹框选中后对应的规格

      show: false,
      quantity: 1,
      popupBtnType: false,

      empty: false,

      shareBoxShow: false,
      shareData: {},
    };
  },
  created() {
    if (this.$route.query.session) {
      this.$store.commit("setSession", this.$route.query.session);
    }
    if (this.$route.query.share) {
      this.share = this.$route.query.share;
    }
    this.id = this.$route.query.id;
    this.getProduct();
  },
  computed: {},
  methods: {
    // 获取商品详情
    getProduct() {
      getProduct(this.id).then((res) => {
        if (res.data.code == 0) {
          this.detail = res.data.data;

          this.shareData = {
            title: this.detail.storeName,
            picUrl: this.detail.images[0],
            content: this.detail.subject,
            openUrl: location.href + "&share=1",
          };

          // 去掉富文本中 a标签的href地址
          let arr = this.detail.description.split('href="');
          arr.map((x, y) => {
            if (y > 0) {
              x = x.slice(x.indexOf('"'));
            }
          });
          this.detail.description = arr.join();

          // 有规格的情况
          if (this.detail.makerProductSkus) {
            this.skuList = this.detail.makerProductSkus;

            // 添加规格
            this.detail.makerProductSkus[0].makerProductSkuAttributeList.map(
              (x) => {
                this.skus.push({ ...x, values: [] });
              }
            );

            // 添加规格的值
            this.skus.map((x, y) => {
              this.skuList.map((i) => {
                let value = i.makerProductSkuAttributeList[y].attributeValue;
                let flag = true;
                x.values.map((n) => {
                  if (n.value == value) {
                    flag = false;
                  }
                });
                flag ? x.values.push({ value, chosen: false }) : null;
              });
            });

            // 去掉空值
            this.skus = this.skus.filter((x) => {
              return x.values[0].value;
            });

            // 选中状态 规格值只有一个 直接选中
            this.skus.map((x) => {
              if (x.values.length == 1) {
                this.chosenSku.push(x.values[0].value);
                x.values[0].chosen = true;
              } else {
                this.chosenSku.push("");
              }
            });

            // 显示的价格
            this.skuList.map((x) => {
              if (!this.priceDown) {
                this.priceDown = x.salePrice;
              } else if (this.priceDown > x.salePrice) {
                this.priceDown = x.salePrice;
              }
              if (!this.priceUp) {
                this.priceUp = x.salePrice;
              } else if (this.priceUp < x.salePrice) {
                this.priceUp = x.salePrice;
              }
            });
          } else {
            this.priceDown = this.detail.salePrice;
          }
        } else {
          this.empty = true;
        }
      });
    },
    //轮播图变换
    swipeChange(index) {
      this.swipeCurrent = index;
    },
    // 联系商家
    connect() {
      if (this.share) {
        this.openApp();
      } else {
        // 原生的联系商家的方法
        console.log(JSON.stringify({ id: this.detail.imAccount }));
        try {
          window.webkit.messageHandlers.contact.postMessage(
            JSON.stringify({ id: this.detail.imAccount })
          );
        } catch {
          try {
            window.android.contact(this.detail.imAccount);
          } catch (error) {
            //
          }
        }
      }
    },
    // 选择规格弹框
    popupShow(type) {
      if (this.share) {
        this.openAppMaker();
      } else {
        this.popupBtnType = type;
        this.show = true;
      }
    },
    // 加入购物车
    addCart() {
      if (this.detail.makerProductSkus) {
        // 有规格
        let flag = true;
        // 规格选完没
        this.chosenSku.map((x) => {
          if (!x) {
            flag = false;
          }
        });
        // 规格选完
        if (flag) {
          if (!this.sku) {
            this.$toast("暂无该规格商品");
            return;
          }
          if (this.quantity > this.sku.amountOnSale) {
            this.$toast("库存不足");
            return;
          }
          const obj = {
            makerShopId: this.detail.shopId,
            spuId: this.detail.productId,
            quantity: this.quantity,
          };
          if (this.sku) {
            obj.skuId = this.sku.skuId;
          }
          this.cartAdd(obj);
        } else {
          this.$toast("请选择规格");
        }
      } else {
        // 无规格
        if (this.quantity > this.detail.amountOnSale) {
          this.$toast("库存不足");
          return;
        }
        const obj = {
          makerShopId: this.detail.shopId,
          spuId: this.detail.productId,
          quantity: this.quantity,
        };
        this.cartAdd(obj);
      }
    },
    // 加入购物车
    cartAdd(obj) {
      addCart(obj).then((res) => {
        if (res.data.code == 0) {
          this.$toast("加入购物车成功");
          this.show = false;
        }
      });
    },
    // 立即购买
    buy() {
      if (this.detail.makerProductSkus) {
        // 有规格
        let flag = true;
        // 规格选完没
        this.chosenSku.map((x) => {
          if (!x) {
            flag = false;
          }
        });
        // 规格选完
        if (flag) {
          if (!this.sku) {
            this.$toast("暂无该规格商品");
            return;
          }
          if (this.quantity > this.sku.amountOnSale) {
            this.$toast("库存不足");
            return;
          }
          // 订单页面需要的参数
          let goodList = [{}];
          goodList[0].storeBasic = { storeName: this.detail.storeName };
          goodList[0].goodArr = [
            {
              addPrice: this.sku.salePrice,
              picUrl: this.detail.images[0],
              spuName: this.detail.subject,
              specInfo: this.chosenSku.join(" "),
              quantity: this.quantity,
              spuId: this.detail.productId,
              skuId: this.sku.skuId,
              makerShopId: this.detail.shopId,
              makerProduct: {
                id: "1",
              },
              makerProductSkuAttributes: this.sku.makerProductSkuAttributeList,
            },
          ];
          goodList[0].goodNum = this.quantity;
          goodList[0].priceAll = this.sku.salePrice * this.quantity;
          goodList[0].id = "1";
          sessionStorage.setItem("goodList", JSON.stringify(goodList));
          this.$router.push("/upOrder");
        } else {
          this.$toast("请选择规格");
        }
      } else {
        // 无规格
        if (this.quantity > this.detail.amountOnSale) {
          this.$toast("库存不足");
          return;
        }
        // 订单页面需要的参数
        let goodList = [{}];
        goodList[0].storeBasic = { storeName: this.detail.storeName };
        goodList[0].goodArr = [
          {
            addPrice: this.priceDown,
            picUrl: this.detail.images[0],
            spuName: this.detail.subject,
            quantity: this.quantity,
            spuId: this.detail.productId,
            makerShopId: this.detail.shopId,
            makerProduct: {
              id: "1",
            },
          },
        ];
        goodList[0].goodNum = this.quantity;
        goodList[0].priceAll = this.priceDown * this.quantity;
        goodList[0].id = "1";
        sessionStorage.setItem("goodList", JSON.stringify(goodList));
        this.$router.push("/upOrder");
      }
    },
    // 弹框确定键点击事件
    popupConfirm() {
      this.popupBtnType === "cart" ? this.addCart() : this.buy();
    },
    // 弹框选择规格
    chooseSku(item2, index) {
      // 规格值的选中状态
      this.skus[index].values.map((x) => {
        x.chosen = false;
      });
      item2.chosen = true;

      // 修改选中的值
      this.chosenSku[index] = item2.value;

      let flag = true;
      // 规格没选完
      this.chosenSku.map((x) => {
        if (!x) flag = false;
      });

      // 判断前清空
      this.sku = null;

      // 规格选完
      if (flag) {
        this.skuList.map((i) => {
          let arr = [];
          // 把规格值拿出来
          i.makerProductSkuAttributeList.map((n) => {
            if (n.attributeValue) {
              arr.push(n.attributeValue);
            }
          });
          let flag2 = true;
          // 拿选中的值与上面的规格值判断
          this.chosenSku.map((x, y) => {
            // 不一样
            if (x !== arr[y]) {
              flag2 = false;
            }
          });
          // 一样的话 赋值
          if (flag2) {
            this.sku = i;
            return;
          }
        });
      } else {
        return;
      }
    },
    jump(type) {
      if (this.share) {
        this.openApp();
      } else {
        try {
          if (this.$route.query.shopCar) {
            this.$router.push("/makerShoppingCart");
          } else {
            window.webkit.messageHandlers.jump.postMessage(
              JSON.stringify({ type: type })
            );
          }
        } catch {
          try {
            window.android.goRecruit(type)?.false;
          } catch {
            //
          }
        }
      }
    },
    // 跳转至店铺详情
    toStore() {
      if (this.share) {
        this.openAppMaker();
      } else {
        this.$router.push({
          path: "makerStoreDetail",
          query: { id: this.detail.shopId },
        });
      }
    },
    openAppMaker() {
      let urls = location.href.split("#/")[1].split("&share=1");
      let url = urls[0] + urls[1];

      this.openApp(url);
    },
  },
};
</script>

<style lang="less" scoped>
.custom-indicator {
  position: absolute;
  right: 16px;
  bottom: 10px;
  font-size: 11px;
  background: rgba(0, 0, 0, 0.3);
  height: 22px;
  width: 34px;
  border-radius: 11px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-inner {
  padding: 16px;
  .info {
    display: flex;
    .info-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 16px;
    }
  }
  .size,
  .num {
    margin: 16px 0;
  }
  .size {
    .size-btn {
      height: 28px;
      color: #999 !important;
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .chosen {
      color: #ff5200 !important;
    }
  }
  .num {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.bottom-list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}
</style>