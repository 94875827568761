<template>
  <div>
    <van-popup
      v-model="_show"
      position="bottom"
      style="
        height: 60px;
        padding: 20px 0;
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
      "
    >
      <div class="bottom-list">
        <div @click="shareGoods(0)">
          <img src="/img/weixin.png" alt="" />
          <p>微信</p>
        </div>
        <div @click="shareGoods(1)">
          <img src="/img/pengyouquan.png" alt="" />
          <p>朋友圈</p>
        </div>
        <div @click="shareGoods(2)">
          <img src="/img/qq.png" alt="" />
          <p>QQ</p>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "shareBox",
  props: {
    data: {
      required: true,
      type: Object,
    },
    show: {
      required: true,
      type: Boolean,
    },
  },
  model: {
    prop: "show",
    event: "change",
  },
  computed: {
    _show: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("change", false);
      },
    },
  },
  methods: {
    shareGoods(type) {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

      let obj = {
        title: this.data.title,
        picUrl: this.data.picUrl,
        content: this.data.content,
        openUrl: this.data.openUrl,
        platformId: type,
      };
      if (isIOS) {
        window.webkit.messageHandlers.share.postMessage(JSON.stringify(obj));
      }
      if (isAndroid) {
        window.android.share(
          obj.platformId,
          "3",
          obj.title,
          obj.content,
          obj.openUrl,
          obj.picUrl
        );
      }
      this.$emit("change", false);
    },
  },
};
</script>

<style lang="less" scoped>
.bottom-list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  > div {
    width: 100px;
    > img {
      width: 32px;
    }
  }
}
</style>